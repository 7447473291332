import React, { createContext, ReactNode, useContext } from "react";
import { ApiError } from "../../api";

export interface ErrorTracking {
  trackUser: (id: string, email: string, name: string) => void;
  untrackUser: VoidFunction;
  trackMetadata: (
    section: string,
    values: {
      [key: string]: any;
    },
  ) => void;
  notifyError: (
    severity: "error" | "info" | "warning",
    message: string,
    metadataSection?: string,
    metadataValues?: {
      [key: string]: any;
    },
  ) => void;
  notifyApiError: (error: ApiError) => void;
}

interface ErrorTrackingProviderProps {
  children?: ReactNode;
  tracking: ErrorTracking;
}

const ErrorTrackingContext = createContext<ErrorTracking | undefined>(
  undefined,
);

const ErrorTrackingProvider = (props: ErrorTrackingProviderProps) => {
  const { children, tracking } = props;

  return (
    <ErrorTrackingContext.Provider value={tracking}>
      {children}
    </ErrorTrackingContext.Provider>
  );
};

export const useErrorTracking = () => {
  const contextValue = useContext(ErrorTrackingContext);
  if (!contextValue) {
    throw new Error(
      "useErrorTracking needs to be used inside a ErrorTrackingProvider.",
    );
  }
  return contextValue;
};

export default ErrorTrackingProvider;
