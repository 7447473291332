import { GetServerSidePropsContext, Redirect } from "next";
import { getClientIp } from "request-ip";
import { toBase64, getCookie, removeCookie } from "../utils";
import {
  getClient,
  getClientServerSettings,
  getClientSetting,
  getPublicToken,
  getUserProfileServer,
  saveUserToken,
  saveRefreshToken,
  getServiceClientSettings,
} from "../api";
import { BrandedAppProps } from "../pages/BrandedApp";
import { AppErrorProps } from "../pages/_app.page";
import tokenCache from "../TokenCache";
import { convertToTimezone } from "./dateUtils";
import axios, { AxiosRequestConfig } from "axios";
import { REFRESH_TOKEN, USER_TOKEN, USER_UUID, baseURL } from "../api.utils";
import * as jsonwebtoken from "jsonwebtoken";

export function isNonBrandedSubdomain(subdomain: string) {
  return subdomain === "app";
}

export function getSubdomain(req: GetServerSidePropsContext["req"]) {
  const host = req.headers.host as string;
  const pphost = req.headers["x-pushpress-host"] as string;
  const subdomain = (pphost || host).split(".")[0];

  return subdomain;
}

export const getAppProps = async (
  context: GetServerSidePropsContext,
): Promise<
  Omit<BrandedAppProps, "sessionId"> | AppErrorProps | { redirect: Redirect }
> => {
  const { req, resolvedUrl: resolvedUrlProp, res } = context;
  const subdomain = getSubdomain(req);

  // refresh service token
  await tokenCache.refreshToken();

  // get client
  let client: Client | undefined = undefined;
  try {
    client = await getClient(subdomain);
    // if client is not found, return 404
  } catch (err) {
    const error = `Failed to get client info for subdomain: ${subdomain}`;
    console.error(error, err);
    return {
      error,
    };
  }

  // if client is inactive, redirect to the webflow 404 page
  if (!client.active) {
    return {
      redirect: {
        permanent: true,
        destination: `https://pushpress.com/404-page`,
      },
    };
  }

  // get google analytics id
  let googleAnalyticsId = null;

  try {
    const respGoogleAnalyticsSetting = await getServiceClientSettings(
      client.uuid,
      "google-analytics",
      "tracking_id",
    );
    googleAnalyticsId = respGoogleAnalyticsSetting?.[0]?.value ?? null;
  } catch (err) {
    console.error("Failed to get client google analytics id", err);
  }

  // get facebook marketing id
  let facebookMarketingId = null;
  try {
    const respFacebookMarketingSetting = await getServiceClientSettings(
      client.uuid,
      "facebook-marketing",
      "pixel_id",
    );

    facebookMarketingId = respFacebookMarketingSetting?.[0]?.value ?? null;
  } catch (err) {
    console.error("Failed to get client facebook marketing id", err);
  }

  // get jwt param
  const jwtParam = getAndValidateJwtParam(context, client);

  // get client theme primary color
  let themePrimaryColor = null;
  try {
    const themePrimaryColorData = await getClientServerSettings(
      client.uuid,
      "core",
      "primary_color",
    );

    if (themePrimaryColorData.length > 0) {
      themePrimaryColor = themePrimaryColorData[0].value;
    }
  } catch (err) {
    console.error("Failed to get client primary custom color", err);
  }

  // get user uuid
  const userUuid = getUserUUid(context);

  // get user and member app links
  let user: User | null = null;
  try {
    if (userUuid) {
      user = await getUserProfileServer(userUuid, client.uuid);
    }
  } catch (err) {
    console.error("Failed to get user", err);
  }

  // get branded member app links
  let appleStoreLink = null;
  let googleStoreLink = null;
  try {
    const data = await getClientSetting(client.uuid);
    appleStoreLink =
      data.data.find((item) => item.name === "apple-store-link")?.value || null;
    googleStoreLink =
      data.data.find((item) => item.name === "google-store-link")?.value ||
      null;
  } catch (error) {
    console.error("Failed to get branded member app links", error);
  }

  // get public token
  const publicRefreshToken = toBase64(process.env.PUBLIC_REFRESH_TOKEN!);
  const { token: publicToken } = await getPublicToken(
    publicRefreshToken,
    client.uuid,
  );

  // Set the public token on the cookie
  const publicTokenCookie = isHttps(context)
    ? `PUBLIC_TOKEN=${publicToken}; path=/; SameSite=None; Secure=true`
    : `PUBLIC_TOKEN=${publicToken}; path=/`;
  res.setHeader("set-cookie", publicTokenCookie);

  // Get landingPagesType
  let landingPagesType: LandingPagesType = "appointment";
  if (req?.url?.includes("appointments")) {
    landingPagesType = "appointment";
  } else if (req?.url?.includes("plans")) {
    landingPagesType = "plan";
  } else if (req?.url?.includes("events")) {
    landingPagesType = "event";
  }

  // get some data from the server
  const resolvedUrl = resolvedUrlProp.split("?")[0];
  const ip = getClientIp(req);
  const today = convertToTimezone(new Date(), client.timezone).getTime();

  // cookie fallback for cases like safari with embed iframes
  const cookiesFallback = {
    PUBLIC_TOKEN: publicToken,
  };

  // get the source
  const source = (context.query?.source as string) || null;

  return {
    client: { ...client, logoUrl: client.logoUrl || "" },
    ssoEnabled: false, // force enabled to false
    user,
    landingPagesType,
    resolvedUrl,
    ip,
    today,
    themePrimaryColor,
    cookiesFallback,
    subdomain,
    source,
    appleStoreLink,
    googleStoreLink,
    jwtParam,
    publicToken,
    facebookMarketingId,
    googleAnalyticsId,
  };
};

const getUserUUid = (context: GetServerSidePropsContext) => {
  const { resolvedUrl } = context;

  //Use provided user uuid if viewing standalone documents landing page
  const [pageType, resourceId] = resolvedUrl.split("/").filter(Boolean);
  if (pageType === "documents") {
    return resourceId;
  }
};

const isHttps = (context: GetServerSidePropsContext) => {
  const reqUrl = context.req.headers["referer"];
  if (!reqUrl) {
    return false;
  }
  const url = new URL(reqUrl!);
  return url.protocol === "https:";
};

const getAndValidateJwtParam = (
  context: GetServerSidePropsContext,
  client: Client,
) => {
  // get param
  const jwt = (context.query?.JWT ?? context.query?.jwt) as string | undefined;
  if (!jwt) {
    return null;
  }

  // decode
  let decoded: { clientUuid: string } | undefined = undefined;
  try {
    decoded = jsonwebtoken.decode(jwt) as { clientUuid: string };
  } catch {
    return null;
  }

  // compare the clientUuid
  if (decoded?.clientUuid !== client.uuid) {
    return null;
  }

  return jwt;
};

export const refreshAccessTokenInterceptor = async <T>(
  config: AxiosRequestConfig<T>,
) => {
  const accessToken = getCookie(USER_TOKEN);
  if (!accessToken) {
    return config;
  }
  await refreshAccessToken(accessToken);
  return config;
};

let isFetchingToken = false;
/**
 * checks if the access token is expired and refreshes it if necessary
 * if the refresh fails, the user is logged out
 *
 * returns the original access token if it's not expired
 * @param accessToken - The access token to refresh
 * @returns The new access token and refresh token
 */
export async function refreshAccessToken(accessToken: string) {
  const decodedToken = jsonwebtoken.decode(accessToken, {
    complete: false,
  }) as {
    exp: number;
  };

  // if refresh token is not set we skip the refresh process
  // and the user access will expire. Once refreshes are implemented
  // the only reason a refresh token should not be set is if the user deleted it
  const refreshToken = getCookie(REFRESH_TOKEN);
  if (!refreshToken) {
    return { accessToken, refreshToken };
  }

  const buffer = 1000 * 60; // refresh tokens 1 minute before they expire
  if (decodedToken.exp * 1000 <= Date.now() - buffer && !isFetchingToken) {
    isFetchingToken = true;
    try {
      const {
        data: { refreshToken: newRefreshToken, accessToken: newAccessToken },
      } = await axios.get<{
        refreshToken: string;
        accessToken: string;
      }>(`${baseURL}/v2/auth/token/access/user`, {
        headers: {
          authorization: `Bearer ${refreshToken}`,
        },
      });

      saveRefreshToken(newRefreshToken);
      saveUserToken(newAccessToken);
      isFetchingToken = false;
      return { accessToken: newAccessToken, refreshToken: newRefreshToken };
    } catch (error) {
      isFetchingToken = false;
      removeCookie(USER_TOKEN);
      removeCookie(USER_UUID);
      removeCookie(REFRESH_TOKEN);
      // TODO: come up with a better way to reset the users experience given this should rarely happen
      window.location.href = "/login";
    }
  }
  return { accessToken, refreshToken };
}
