/**
 * Capitalizes the first letter of each word.
 */
export const toTitleCase = (s: string) =>
  s
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");

/**
 * Allows us to use multiple classes with css modules.
 * Ex: <Component className={joinClasses(styles.button, styles.primary)} />
 */
export function joinClasses(...classes: unknown[]): string {
  return classes.filter(Boolean).join(" ");
}

/**
 * Allows for simple referencing of module classes/ids, multiple class selection,
 * conditional class assignments, and ability to use kebab-case.
 * Higher-order function that first accepts the exported CSS module as it's argument.
 * The returned function accepts an arbitrary number of arguments, either being one of:
 *   - Class name (ex: 'primary', 'container')
 *   - ConditionalClasses tuple, defined below
 *     Ex:  [ props.active, 'active', 'deactivated' ]
 *          [ x === 'expired', ['expired', 'expired--warning'] ] //false styles are optional
 * Usage:
 *    const S = multiStyles(importedScssModule);
 *    <input className={S('txt-input', [props.active, 'txt-input--active'])} />
 */
type SCSSModuleObject = { [key: string]: string };
type ConditionalClasses = [boolean, string | string[], (string | string[])?];
export const multiStyles =
  (styles: SCSSModuleObject) =>
  (...args: (string | ConditionalClasses)[]): string => {
    // These are pre-processed class names
    const singleClasses: string[] = [];
    const conditionalClasses: ConditionalClasses[] = [];
    args.forEach((x) =>
      typeof x === "string"
        ? singleClasses.push(x)
        : conditionalClasses.push(x),
    );

    // List containing processed module class names
    const moduleClasses = singleClasses.map((s) => styles[s]);

    // Evaluate and append conditional styles
    conditionalClasses.forEach(([condition, trueClasses, falseClasses]) => {
      if (!condition && !falseClasses) {
        return;
      }
      const classes = condition ? trueClasses : falseClasses;
      if (typeof classes === "string") {
        moduleClasses.push(styles[classes]);
      } else {
        classes?.forEach((c) => moduleClasses.push(styles[c]));
      }
    });

    return moduleClasses.join(" ");
  };

/*
 * Simple valid email format check
 */
export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/**
 * Formats integer values to USD string.
 * e.g.   400 -> "$400.00"
 *      45.50 ->  "$45.50"
 */
export const formatIntToUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

/**
 * Returns timezone in client. ex: "America/Los_Angeles"
 */
export const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Returns time string from timestamp.
 * toTimeString("2022-03-09T17:00:00.000Z") -> "9:00 am"
 */
export const toTimeString = (timestamp: string) => {
  const d = new Date(timestamp);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.toLocaleTimeString().toLowerCase().replace(":00 ", " ");
};

/**
 * Convert seconds into minutes
 */
export const secsToMins = (seconds: number) => seconds / 60;

/*
 *  Determines the device's OS. Note, there might exist a more robust solution.
 */
export const getMobileOS = (): MobileOS => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return "android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "ios";
  }
  return "other";
};

/*
 *  Check if it is apple device
 */
export const getIsAppleDevice = () => {
  const ua = navigator.userAgent;

  return (
    /iPhone|iPad|iPod|Macintosh/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
};

/*
 * Check if it is mobile
 */
export const isMobile = (): boolean => {
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  ) {
    isMobile = true;
  }

  return isMobile;
};

/**
 * Formats an input's text using a phone number format as the user types.
 * It automatically inserts the country code and all spaces, parantheses, and dashes
 */
export const parsePhoneInput = (
  value: string,
  countryCode = "",
): string | null => {
  const parsedPhone = value
    .replaceAll(countryCode, "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("-", "")
    .replaceAll(" ", "");

  // Handle area code input deletion
  let areaCodeLength = 3;
  if (value.includes("(") && !value.includes(")")) {
    areaCodeLength = value.slice(value.indexOf("(") + 1).length - 1;
  }

  const phoneLength = parsedPhone.length;
  if (!phoneLength || !areaCodeLength) {
    return "";
  }

  if (isNaN(parseInt(parsedPhone))) {
    return null;
  }

  let phone = countryCode;
  const areaCode = `(${parsedPhone.slice(0, areaCodeLength)})`;
  const firstSet = ` ${parsedPhone.slice(3, 6)}`;
  const secondSet = ` - ${parsedPhone.slice(6, 10)}`;

  if (phoneLength <= 3) {
    phone += areaCode;
  } else if (phoneLength <= 6) {
    phone += areaCode + firstSet;
  } else if (phoneLength <= 10) {
    phone += areaCode + firstSet + secondSet;
  } else {
    return null;
  }

  return phone;
};

export const ISOTimestampFromDateTime = (
  date: string | number,
  time: string | number,
) => {
  const d = new Date(date).toDateString();
  const t = new Date(time).toTimeString();
  return new Date(`${d} ${t}`).toISOString();
};

export const extractError = (err: any) => {
  if (typeof err === "string") {
    return err;
  } else if (err?.message) {
    return err.message;
  } else if (err?.error && typeof err?.err === "string") {
    return err.error;
  } else if (err?.data?.message) {
    return err.data.message;
  } else if (err?.data?.error && typeof err?.data?.error === "string") {
    return err.data.error;
  } else if (
    err?.data?.error?.message &&
    typeof err?.data?.error?.message === "string"
  ) {
    return err.data.error.message;
  } else if (err?.data?.errors && typeof err?.data?.errors === "object") {
    return Object.values(err.data.errors)[0];
  }
  return "Something went wrong.";
};

export const getBillingTypeName = (
  billingType: string,
): PaymentMethodType | null => {
  switch (billingType) {
    case "A":
      return "bank";
    case "B":
      return "becs";
    case "N":
      return "acss";
    case "C":
      return "card";
    case "L":
      return "link";
    case "U":
      return "bacs";
    case "P":
      return "sepa";
    default:
      return null;
  }
};

export const formatSavedPaymentLabel = (p: PaymentMethod) => {
  switch (p.type) {
    case "bank":
      return `Bank ********* ****${p.lastFour}`;
    case "card":
      return `Card **** **** **** ${p.lastFour}`;
    case "link":
      return `Stripe Link`;
    default:
      return `Bank ********* ****${p.lastFour}`;
  }
};

export const mountDayYearDateToIso = (str: string) => {
  const parts = str.split("/");
  if (parts.length !== 3) {
    return str;
  }
  const [month, day, year] = parts;
  return `${year}-${month}-${day}T00:00:00Z`;
};

export const getTodayTimeZero = () => {
  const parts = new Date().toISOString().split("T");
  return new Date(`${parts[0]}T00:00:00Z`).getTime();
};

export const formatNumberToPercent = (nu: number) => {
  const formated = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
  }).format(nu);
  return `${formated}%`;
};

export const addTaxSuffixIfNecessary = (name: string) => {
  if (name.toLocaleLowerCase().trim().endsWith("tax")) {
    return name;
  }
  return `${name} Tax`;
};

export const getFriendlyTaxName = (
  tax?: { name: string; rate: number } | null,
) => {
  if (!tax) {
    return "Tax";
  }
  const name = tax.name ? addTaxSuffixIfNecessary(tax.name) : "Tax";
  const rate = tax.rate ? ` (${tax.rate}%)` : "";
  return `${name}${rate}`;
};

export const isMinorParticipant = (dob?: Date | number) => {
  if (!dob) {
    return false;
  }
  const today = new Date();
  const dobTime = new Date(dob).getTime();
  const eighteenYearsAgo = new Date(
    Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()),
  );
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  return dobTime > eighteenYearsAgo.getTime();
};

export const upperFirst = (text: string) => {
  const first = text.substring(0, 1).toUpperCase();
  const rest = text.substring(1);
  return `${first}${rest}`;
};

export const getCookie = (name: string) => {
  const value = document.cookie;
  const parts = value.split(`;`);
  const param = parts.find((part) => {
    const n = part.trim().split("=")[0];
    return n === name;
  });
  const result = param ? param.split("=")[1] : "";
  return result || getCookieFallback(name);
};

export interface CookieOptions {
  expires?: Date;
  secure?: boolean;
  sameSite?: "None" | "Strict" | "Lax";
  domain?: string;
}

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {},
) => {
  setCookieFallback(name, value);
  const arr = [];
  arr.push(`${name}=${value}`);
  if (options?.expires) {
    arr.push(`expires=${options.expires.toUTCString()}`);
  }
  arr.push(`path=/`);
  if (options?.secure) {
    arr.push(`Secure=true`);
  }
  if (options?.sameSite) {
    arr.push(`SameSite=${options?.sameSite}`);
  }
  if (options?.domain) {
    arr.push(`Domain=${options.domain}`);
  }
  const cookieValue = arr.join(";");

  document.cookie = cookieValue;
};

export const removeCookie = (name: string) => {
  removeCookieFallback(name);
  setCookie(name, "", { expires: new Date(0) });
};

export const setCookiesFallback = (data: { [key: string]: string }) => {
  (window as any).cookiesFallback = data;
};

export const getCookiesFallback = (): { [key: string]: string } => {
  return (window as any).cookiesFallback || {};
};

export const getCookieFallback = (name: string) => {
  const data = getCookiesFallback();
  return data[name];
};

export const setCookieFallback = (name: string, value: string) => {
  const data = getCookiesFallback();
  setCookiesFallback({
    ...data,
    [name]: value,
  });
};

export const removeCookieFallback = (name: string) => {
  const data = getCookiesFallback();
  const nextData = { ...data };
  delete nextData[name];
  setCookiesFallback(nextData);
};

export const toBase64 = (text: string) => {
  return Buffer.from(text).toString("base64");
};

export const isHttps = () => {
  return document.location.protocol === "https:";
};

/**
 * Ex: changeOffsetToUtc('2022-10-13T19:30:00.000-07:00'); // returns 2022-10-13T19:30:00.000Z
 */
export const changeOffsetToUtc = (dateIso: string) => {
  const dateSplit = dateIso.split("T");
  if (dateSplit.length !== 2) {
    return dateIso;
  }
  const [date, time] = dateSplit;
  if (time.includes("-")) {
    const timeWithoutOffset = time.split("-")[0];
    return `${date}T${timeWithoutOffset}Z`;
  } else if (time.includes("+")) {
    const timeWithoutOffset = time.split("+")[0];
    return `${date}T${timeWithoutOffset}Z`;
  }
  return dateIso;
};

export const parseBase64 = (content: string) => {
  const decoded = Buffer.from(content as any, "base64")?.toString();
  return JSON.parse(decoded);
};

const EXISTENT_USER_MESSAGE = "A user with this email exist, try login.";

export const retryCreateUser = (failureCount: number, error: any) => {
  if (error.message === EXISTENT_USER_MESSAGE) {
    return false;
  }
  return failureCount < 3;
};

export const validLocales = [
  "af",
  "ar-dz",
  "ar-kw",
  "ar-ly",
  "ar-ma",
  "ar-sa",
  "ar-tn",
  "ar",
  "az",
  "be",
  "bg",
  "bm",
  "bn",
  "bo",
  "br",
  "bs",
  "ca",
  "cs",
  "cv",
  "cy",
  "da",
  "de-at",
  "de-ch",
  "de",
  "dv",
  "el",
  "en-au",
  "en-ca",
  "en-gb",
  "en-ie",
  "en-nz",
  "eo",
  "es-do",
  "es-us",
  "es",
  "et",
  "eu",
  "fa",
  "fi",
  "fo",
  "fr-ca",
  "fr-ch",
  "fr",
  "fy",
  "gd",
  "gl",
  "gom-latn",
  "gu",
  "he",
  "hi",
  "hr",
  "hu",
  "hy-am",
  "id",
  "is",
  "it",
  "ja",
  "jv",
  "ka",
  "kk",
  "km",
  "kn",
  "ko",
  "ky",
  "lb",
  "lo",
  "lt",
  "lv",
  "me",
  "mi",
  "mk",
  "ml",
  "mr",
  "ms-my",
  "ms",
  "mt",
  "my",
  "nb",
  "ne",
  "nl-be",
  "nl",
  "nn",
  "pa-in",
  "pl",
  "pt-br",
  "pt",
  "ro",
  "ru",
  "sd",
  "se",
  "si",
  "sk",
  "sl",
  "sq",
  "sr-cyrl",
  "sr",
  "ss",
  "sv",
  "sw",
  "ta",
  "te",
  "tet",
  "th",
  "tl-ph",
  "tlh",
  "tr",
  "tzl",
  "tzm-latn",
  "tzm",
  "uk",
  "ur",
  "uz-latn",
  "uz",
  "vi",
  "x-pseudo",
  "yo",
  "zh-cn",
  "zh-hk",
  "zh-tw",
];

export const base64Encode = (str: string) => {
  return Buffer.from(str).toString("base64");
};

export const getSignDocumentsUrl = (subdomain: string, userUuid: string) => {
  const localUrl = `https://${subdomain}.pushpressdev.com/open/documents/${userUuid}`;
  const url = `${window.location.origin}/open/documents/${userUuid}`;
  return process.env.NEXT_PUBLIC_ENV === "local" ? localUrl : url;
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
