import Head from "next/head";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n/config";
import ErrorTrackingProvider from "../components/ErrorTrackingProvider/BugsnagTrackingProvider";
import ClarityScript from "../components/ClarityScript";
import ApolloProvider from "../graphql";
import { ToastContainer } from "react-toastify";
import { useAnalytics } from "../components/AnalyticsProvider";
import { ReactNode, useEffect } from "react";
import favicon from "../../public/images/favicon.ico";
import {
  Experiment,
  Result,
  useGrowthBook,
} from "@growthbook/growthbook-react";
import { getGrowthBookCookie } from "../utils/growthBookUtils";

const NonBrandedContent = ({ children }: { children: ReactNode }) => {
  const gb = useGrowthBook();
  const analytics = useAnalytics();

  useEffect(() => {
    gb.setAttributes({
      id: "",
      sessionId: getGrowthBookCookie(),
    });
    gb.setTrackingCallback(
      (experiment: Experiment<any>, result: Result<any>) => {
        analytics.trackEvent("experiment_viewed", {
          userUuid: "",
          anonymousId: getGrowthBookCookie(),
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    );
  }, [analytics, gb]);

  return (
    <div
      suppressHydrationWarning
      style={{
        position: "relative",
        zIndex: 1,
        height: "100%",
      }}
    >
      <Head>
        <title>PushPress</title>
        <link rel="icon" href={favicon.src} />
        <meta property="og:title" content="PushPress" />
        <meta property="twitter:title" content="PushPress" />
        <meta property="og:image" content={favicon.src} />
        <meta property="twitter:image" content={favicon.src} />
        <meta
          name="viewport"
          content="initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <ClarityScript projectId={process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID} />

      <I18nextProvider i18n={i18n}>
        <ErrorTrackingProvider>
          <ApolloProvider>
            <ToastContainer position="bottom-center" newestOnTop />
            {children}
          </ApolloProvider>
        </ErrorTrackingProvider>
      </I18nextProvider>
    </div>
  );
};

export default NonBrandedContent;
