import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";

export type AnalyticType =
  | "googleAnalytics"
  | "facebookPixel"
  | "segment"
  | "heap"
  | "hubspot";

export interface AnalyticsPlugin {
  name: AnalyticType;
  renderComponent?: () => JSX.Element | null;
  trackEvent?: (name: string, params?: object, onlyInternal?: boolean) => void;
  trackIdentify?: (
    name: string,
    params?: object,
    onlyInternal?: boolean,
  ) => void;
  trackPageView?: (url?: string) => void;
  trackUser?: (userId?: string, params?: object) => void;
  trackGroup?: (name: string, params?: object) => void;
}

export interface AnalyticsOptions {
  segmentWriteKey?: string;
  googleAnalyticsTrackingId?: string;
  facebookPixelId?: string;
}
interface AnalyticsContextValue {
  trackEvent: (name: string, params?: object, onlyInternal?: boolean) => void;
  trackIdentify?: (
    name: string,
    params?: object,
    onlyInternal?: boolean,
  ) => void;
  trackPageView?: (url: string) => void;
  trackUser?: (userId?: string, params?: object) => void;
  trackGroup?: (name: string, params?: object) => void;
  initializedPlugins: AnalyticType[];
}

interface AnalyticsProviderProps {
  children?: ReactNode;
  options?: AnalyticsOptions;
  plugins?: AnalyticsPlugin[];
}

const AnalyticsContext = createContext<AnalyticsContextValue | undefined>(
  undefined,
);

const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const { children, plugins = [] } = props;

  const trackPageView = useCallback(
    (url: string) => {
      plugins.forEach((plugin) => {
        plugin.trackPageView?.(url);
      });
    },
    [plugins],
  );

  const trackEvent = useCallback(
    (name: string, params?: object, onlyInternal?: boolean) => {
      plugins.forEach((plugin) => {
        plugin.trackEvent?.(name, params, onlyInternal);
      });
    },
    [plugins],
  );

  const trackIdentify = useCallback(
    (name: string, params?: object, onlyInternal?: boolean) => {
      plugins.forEach((plugin) => {
        plugin.trackIdentify?.(name, params, onlyInternal);
      });
    },
    [plugins],
  );

  const trackGroup = useCallback(
    (name: string, params?: object) => {
      plugins.forEach((plugin) => {
        plugin.trackGroup?.(name, params);
      });
    },
    [plugins],
  );

  const contextValue = useMemo(() => {
    const initializedPlugins = plugins.map((p) => p.name);
    return {
      trackEvent,
      trackGroup,
      trackPageView,
      trackIdentify,
      initializedPlugins,
    };
  }, [trackEvent, plugins, trackGroup, trackPageView, trackIdentify]);

  return (
    <AnalyticsContext.Provider value={contextValue}>
      {children}
      {plugins.map((plugin) => (
        <React.Fragment key={plugin.name}>
          {plugin.renderComponent?.() || null}
        </React.Fragment>
      ))}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const contextValue = useContext(AnalyticsContext);
  if (!contextValue) {
    throw new Error(
      "useAnalytics needs to be used inside a AnalyticsProvider.",
    );
  }
  return contextValue;
};

export const useClientPageView = () => {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.trackPageView?.(location.pathname);
  }, [location.pathname, analytics]);
};

export const ClientPageView = () => {
  useAnalytics();
  return null;
};

export default AnalyticsProvider;
