import { ReactNode } from "react";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DocumentNode,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { PUBLIC_TOKEN, USER_TOKEN, baseURL } from "../api.utils";
import { getCookie } from "../utils";
import tokenCache from "../TokenCache";
import { refreshAccessToken } from "../utils/server";

const httpLink = createHttpLink({
  uri: `${baseURL}/v2/graph/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = getCookie(USER_TOKEN) || getCookie(PUBLIC_TOKEN);

  if (!token) {
    return {
      headers,
    };
  }

  const { accessToken } = await refreshAccessToken(token);
  if (!accessToken) {
    return {
      headers,
    };
  }

  return {
    headers: {
      authorization: `Bearer ${token}`,
      ...headers,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

type GraphQLProviderType = {
  children: ReactNode;
};

const GraphQLProvider = ({ children }: GraphQLProviderType) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const graphAxios = axios.create({
  baseURL,
  validateStatus: null,
});

export const executeGraphQl = async <T, V>(
  documentNode: DocumentNode,
  variables: V,
  tokenParam?: string,
): Promise<T> => {
  const token = tokenParam ?? (await tokenCache.refreshToken());
  return graphAxios
    .post(
      `${baseURL}/v2/graph/graphql`,
      {
        query: documentNode?.loc?.source.body,
        variables,
      },
      {
        headers: {
          contentType: "application/json",
          accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      console.error("GraphQL request failed", error);
    });
};

export default GraphQLProvider;
