import React, { ReactNode, useCallback, useMemo } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ApiError } from "../../api";
import { extractError } from "../../utils";
import ErrorTrackingProvider from ".";

interface BugsnagTrackingProviderProps {
  children?: ReactNode;
}

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NEXT_PUBLIC_ENV,
    appVersion: "1.0.4", // TODO: Figure out a way to autoincrement
  });
}

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(
  React,
) as any;

const BugsnagTrackingProvider = (props: BugsnagTrackingProviderProps) => {
  const { children } = props;

  const trackUser = useCallback((id: string, email: string, name: string) => {
    Bugsnag.setUser(id, email, name);
  }, []);

  const untrackUser = useCallback(() => {
    Bugsnag.setUser();
  }, []);

  const trackMetadata = useCallback(
    (
      section: string,
      values: {
        [key: string]: any;
      },
    ) => {
      Bugsnag.addMetadata(section, values);
    },
    [],
  );

  const notifyError = useCallback(
    (
      severity: "error" | "info" | "warning",
      message: string,
      metadataSection?: string,
      metadataValues?: {
        [key: string]: any;
      },
    ) => {
      Bugsnag.notify(message, (event) => {
        event.severity = severity;
        if (metadataSection && metadataValues) {
          event.addMetadata(metadataSection, metadataValues);
        }
      });
    },
    [],
  );

  const notifyApiError = useCallback((error: ApiError) => {
    const message = extractError(error);
    Bugsnag.notify(`Api: ${message}`, (event) => {
      event.severity = "warning";
      event.addMetadata("response", {
        url: error.res.request.responseURL,
        method: error.res.config.method,
        payload: error.res.config.data,
        status: error.res.status,
        statusText: error.res.statusText,
        data: error.res.data,
      });
    });
  }, []);

  const tracking = useMemo(() => {
    return {
      trackUser,
      untrackUser,
      trackMetadata,
      notifyError,

      notifyApiError,
    };
  }, [trackUser, untrackUser, trackMetadata, notifyError, notifyApiError]);

  return (
    <ErrorTrackingProvider tracking={tracking}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ErrorTrackingProvider>
  );
};

export default BugsnagTrackingProvider;
