import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import LocizeBackend from "i18next-locize-backend";
import { LOCIZE_BACKEND } from "../utils/features";
import commonEn from "./en-US/common.json";
import appointmentsEn from "./en-US/appt.json";
import eventsEn from "./en-US/events.json";
import planEn from "./en-US/plan.json";
import calendarEn from "./en-US/calendar.json";
import signupEN from "./en-US/signup.json";
import memberQRCodeEN from "./en-US/memberQRCode.json";
import signinEN from "./en-US/signin.json";
import invoiceEn from "./en-US/invoice.json";

const resources = {
  "en-US": {
    appt: appointmentsEn,
    common: commonEn,
    events: eventsEn,
    plan: planEn,
    invoice: invoiceEn,
    calendar: calendarEn,
    signup: signupEN,
    signin: signinEN,
    memberQRCode: memberQRCodeEN,
  },
} as const;

const baseResources = resources["en-US"];

declare module "react-i18next" {
  interface CustomTypeOptions {
    resources: typeof baseResources;
  }
}

const backendOptions = {
  projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID!,
  referenceLng: "en-US",
};

const i18next = i18n;

if (LOCIZE_BACKEND) {
  i18next
    .use(LocizeBackend)
    .use(LanguageDetector)
    .init({
      debug: false,
      fallbackLng: "en-US",
      interpolation: {
        escapeValue: false,
      },
      backend: backendOptions,
    });
} else {
  i18next.use(LanguageDetector).init({
    debug: false,
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
    resources,
  });
}

export default i18next;
