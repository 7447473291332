import Script from "next/script";
import pRetry from "p-retry";
import { AnalyticsPlugin, AnalyticType } from ".";

class FacebookPixelPlugin implements AnalyticsPlugin {
  id: string;
  landingPagesType: string;
  name: AnalyticType;

  constructor(id: string, landingPagesType: string) {
    this.id = id;
    this.landingPagesType = landingPagesType;
    this.name = "facebookPixel";
  }

  getFbq() {
    if (!("fbq" in window)) {
      throw new Error("No fbq");
    }
    return window["fbq" as any] as any;
  }

  trackEvent(name: string, params?: object, onlyInternal?: boolean) {
    if (onlyInternal) {
      return;
    }
    const track = async () => {
      const fbq = this.getFbq();
      fbq("track", `landing.${this.landingPagesType}.${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackPageView() {
    const track = async () => {
      const fbq = this.getFbq();
      fbq("track", "PageView");
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackUser() {
    // TODO: check how to do this
  }

  renderComponent() {
    return (
      <>
        <Script id="fb-pixel" strategy="lazyOnload" key="facebook-pixel">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${this.id}');
        `}
        </Script>
        <noscript>
          <img
            height="1"
            width="1"
            style={{
              display: "none",
            }}
            src={`https://www.facebook.com/tr?id=${this.id}&ev=PageView&noscript=1`}
          />
        </noscript>
      </>
    );
  }
}

export default FacebookPixelPlugin;
