import Script from "next/script";
import pRetry from "p-retry";
import React from "react";
import { AnalyticsPlugin, AnalyticType } from ".";

class GoogleAnalyticsPlugin implements AnalyticsPlugin {
  id: string;
  landingPagesType: string;
  name: AnalyticType;

  constructor(id: string, landingPagesType: string) {
    this.id = id;
    this.landingPagesType = landingPagesType;
    this.name = "googleAnalytics";
  }

  getGtag() {
    if (!("gtag" in window)) {
      throw new Error("No gtag");
    }
    return window["gtag" as any] as any;
  }

  trackEvent(name: string, params?: object, onlyInternal?: boolean) {
    if (onlyInternal) {
      return;
    }
    const track = async () => {
      const gtag = this.getGtag();
      gtag("event", `landing.${this.landingPagesType}.${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackPageView(url?: string) {
    const track = async () => {
      const gtag = this.getGtag();
      gtag("config", this.id, { page_path: url });
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackUser(userId?: string) {
    const track = async () => {
      const gtag = this.getGtag();
      gtag("config", this.id, { user_id: userId });
    };
    pRetry(track, {
      retries: 5,
    });
  }

  renderComponent() {
    return (
      <React.Fragment key="google-analytics">
        <Script
          strategy="lazyOnload"
          src={`https://www.googletagmanager.com/gtag/js?id=${this.id}`}
        />
        <Script strategy="lazyOnload">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${this.id}', {
            page_path: window.location.pathname,
            });
          `}
        </Script>
      </React.Fragment>
    );
  }
}

export default GoogleAnalyticsPlugin;
