import React from "react";
import AnalyticsProvider from "./../AnalyticsProvider";
import { isCypress } from "../../api.utils";
import SegmentPlugin from "../AnalyticsProvider/segmentPlugin";
import FacebookPixelPlugin from "../AnalyticsProvider/facebookPixelPlugin";
import GoogleAnalyticsPlugin from "../AnalyticsProvider/googleAnalyticsPlugin";
import HeapPlugin from "../AnalyticsProvider/heapPlugin";

function BrandedAnalyticsProvider({
  googleAnalyticsId,
  facebookMarketingId,
  landingPagesType,
  children,
}: {
  googleAnalyticsId?: string | null;
  facebookMarketingId?: string | null;
  landingPagesType: string;
  children: React.ReactNode;
}) {
  const plugins = [];
  const segmentWriteKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
  const heapAnalyticsAppId = process.env.NEXT_PUBLIC_HEAP_ANALYTICS_APP_ID;

  if (segmentWriteKey) {
    plugins.push(new SegmentPlugin(segmentWriteKey, landingPagesType));
  }
  if (facebookMarketingId) {
    plugins.push(
      new FacebookPixelPlugin(facebookMarketingId, landingPagesType),
    );
  }
  if (googleAnalyticsId) {
    plugins.push(
      new GoogleAnalyticsPlugin(googleAnalyticsId, landingPagesType),
    );
  }
  if (heapAnalyticsAppId) {
    plugins.push(new HeapPlugin(heapAnalyticsAppId, landingPagesType));
  }

  return (
    <AnalyticsProvider plugins={isCypress ? [] : plugins}>
      {children}
    </AnalyticsProvider>
  );
}

export default BrandedAnalyticsProvider;
