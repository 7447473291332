import styles from "./ErrorPage.module.scss";

interface ErrorPageProps {
  message: string;
}

const ErrorPage = (props: ErrorPageProps) => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>OOPS!</h1>
      <p className={styles.message}>{props.message}</p>
    </div>
  );
};

export default ErrorPage;
