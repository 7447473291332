import Script from "next/script";
import pRetry from "p-retry";
import { AnalyticsPlugin, AnalyticType } from ".";

class SegmentPlugin implements AnalyticsPlugin {
  segmentWriteKey: string;
  name: AnalyticType;
  prefix?: string;

  constructor(segmentWriteKey: string, prefix?: string) {
    this.segmentWriteKey = segmentWriteKey;

    this.prefix = prefix || "";
    this.name = "segment";
  }

  getAnalytics() {
    if (!("analytics" in window)) {
      throw new Error("No analytics");
    }
    return window["analytics" as any] as any;
  }

  trackEvent(name: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();

      analytics.track(`${this.prefix}${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackGroup(name: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics?.group(`${this.prefix}${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackIdentify(name: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics?.identify(`${this.prefix}${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackPageView(page?: string) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics?.page(page);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackUser(userId?: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics.identify(userId, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  renderComponent() {
    return (
      <Script key="segment-plugin">
        {`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.15.2";
          analytics.load("${this.segmentWriteKey}");
          analytics.page();
          }}();
        `}
      </Script>
    );
  }
}

export default SegmentPlugin;
