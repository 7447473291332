import { serviceAxios, endpoints, setServiceAccessToken } from "./api";

const serviceToken = process.env.SERVICE_REFRESH_TOKEN;
const serviceRefreshToken = serviceToken
  ? Buffer.from(serviceToken!).toString("base64")
  : undefined;

/**
 * Retrieves a service auth token and caches it for as long as it is valid.
 */
class TokenCache {
  private token: string;
  private expiresAt: number; // "2022-03-09T16:45:10.611Z"

  constructor() {
    this.token = "";
    this.expiresAt = 0;
    this.requestNewToken = this.requestNewToken.bind(this);
  }

  public async refreshToken() {
    const tokenExpired = Date.now() >= this.expiresAt;
    return tokenExpired ? await this.requestNewToken() : this.token;
  }

  private requestNewToken() {
    return serviceAxios
      .post(endpoints.auth + "/token/access", undefined, {
        headers: {
          authorization: `Basic ${serviceRefreshToken}`,
        },
      })
      .then((response) => {
        const { token, expires_at: expiresAt } = response.data;
        this.token = token;
        this.expiresAt = Date.parse(expiresAt);
        setServiceAccessToken(token);
        return token;
      })
      .catch(() => {
        console.error("Auth failed.");
        return "";
      });
  }
}

const tokenCache = new TokenCache();

export default tokenCache;
