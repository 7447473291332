import Head from "next/head";
import favicon from "../../public/images/favicon.ico";
import { AppPageProps } from "./_app.page";

function BrandedMetaTags(props: AppPageProps) {
  const faviconHref = props.favicon || favicon.src;

  return (
    <Head>
      <title>{props.title}</title>
      <link rel="icon" href={faviconHref} />
      <meta property="og:title" content={props.title} />
      <meta property="twitter:title" content={props.title} />
      <meta property="og:image" content={faviconHref} />
      <meta property="twitter:image" content={faviconHref} />
      <meta
        name="viewport"
        content="initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Head>
  );
}

export default BrandedMetaTags;
