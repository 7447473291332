import Script from "next/script";
import pRetry from "p-retry";
import { AnalyticsPlugin, AnalyticType } from ".";

class HeapPlugin implements AnalyticsPlugin {
  heapAnalyticsAppId: string;
  name: AnalyticType;
  prefix?: string;

  constructor(heapAnalyticsAppId: string, prefix?: string) {
    this.heapAnalyticsAppId = heapAnalyticsAppId;
    this.prefix = prefix || "";
    this.name = "heap";
  }

  getAnalytics() {
    if (!("heap" in window)) {
      throw new Error("No heap");
    }
    return window["heap" as any] as any;
  }

  trackEvent(name: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics.track(`${this.prefix}.${name}`, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  trackUser(userId?: string, params?: object) {
    const track = async () => {
      const analytics = this.getAnalytics();
      analytics.identify(userId, params);
    };
    pRetry(track, {
      retries: 5,
    });
  }

  renderComponent() {
    return (
      <Script id="heap-analytics">
        {`
          (function() {
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load(${this.heapAnalyticsAppId})
          })();
        `}
      </Script>
    );
  }
}

export default HeapPlugin;
