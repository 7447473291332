import { GrowthBook } from "@growthbook/growthbook";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export const generateGrowthBook = () => {
  return new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: process.env.NEXT_PUBLIC_ENV !== "production",
  });
};

export const GB_UUID_COOKIE = "gb_anonymous_id";
export const getGrowthBookCookie = () => {
  let uuid = Cookies.get(GB_UUID_COOKIE);
  if (!uuid) {
    uuid = uuidv4();
    Cookies.set(GB_UUID_COOKIE, uuid);
  }
  return Cookies.get(GB_UUID_COOKIE);
};
